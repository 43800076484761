import axios from "axios"

const client = axios.create({
  // baseURL: "https://api-mainnet-graphql.raynet.work/",
  baseURL: "http://18.118.55.153:8099",
})

export const getNetworkInfo = async () => {
  return client.post("/", {
    query: `
      {
        cardano {
          tip {
            number
            slotNo
          }
          currentEpoch {
            number
            startedAt
            blocksCount
          }
        }
      }
    `,
  })
}
